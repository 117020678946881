import { CommissionDocumentPayerResponse } from '../openapi/arrakis';

export const constructBillTo = (
  payer: CommissionDocumentPayerResponse,
): string => {
  let string = payer.fullName!;
  if (payer.address) {
    string += `, ${payer.address}`;
  }

  if (payer.emailAddress) {
    string += ` - ${payer.emailAddress}`;
  }

  return string;
};
