import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppThunk,
  EnumMap,
  ErrorCode,
  ToastNotificationData,
  ToastNotificationState,
  ToastNotificationType,
} from '../types';

export const initialState: ToastNotificationState = {
  visible: false,
  toastData: undefined,
};

// TODO: Handle apiError.debugMessage
const ToastNotificationSlice = createSlice({
  name: 'toastNotification',
  initialState,
  reducers: {
    showToastNotification(state, action: PayloadAction<ToastNotificationData>) {
      state.visible = true;
      state.toastData = action.payload;
    },
    resetToastNotification(state) {
      state.visible = false;
      state.toastData = undefined;
    },
  },
});

export const {
  showToastNotification,
  resetToastNotification,
} = ToastNotificationSlice.actions;

export const showToastByTimer = (
  type: ToastNotificationType,
  title: string,
  subtitle?: string,
  timer = 3000,
): AppThunk => async (dispatch) => {
  dispatch(
    showToastNotification({
      type,
      title,
      subtitle,
    }),
  );

  setTimeout(() => {
    dispatch(resetToastNotification());
  }, timer);
};

export const showSuccessToast = (
  title: string,
  subtitle?: string,
  timer = 3000,
): AppThunk => async (dispatch) => {
  dispatch(showToastByTimer('success', title, subtitle, timer));
};

export const showErrorToast = (
  title: string,
  subtitle?: string,
  timer = 3000,
): AppThunk => async (dispatch) => {
  dispatch(showToastByTimer('error', title, subtitle, timer));
};

export const showErrorToastForErrorCode = (
  title: string,
  errorCode: ErrorCode,
  timer = 3000,
): AppThunk => async (dispatch) => {
  const codeToSubtitle: EnumMap<ErrorCode, string> = {
    NOT_FOUND: 'We could not find what you were looking for.',
    UNAUTHORIZED: 'You are not authorized to access that.',
    FORBIDDEN: 'You cannot perform that action.',
    SERVER_ERROR:
      'An unexpected error occurred. Please try again in a few moments or contact support.',
  };

  dispatch(showErrorToast(title, codeToSubtitle[errorCode], timer));
};

export const showInfoToast = (
  title: string,
  subtitle?: string,
  timer = 3000,
): AppThunk => async (dispatch) => {
  dispatch(showToastByTimer('info', title, subtitle, timer));
};

export const showWarningToast = (
  title: string,
  subtitle?: string,
  timer = 3000,
): AppThunk => async (dispatch) => {
  dispatch(showToastByTimer('warning', title, subtitle, timer));
};

export default ToastNotificationSlice.reducer;
